export const soalMatematika = [
  {
    answer: "a",
  },

  {
    answer: "a",
  },
  {
    answer: "a",
  },

  {
    answer: "a",
  },
  {
    answer: "a",
  },

  {
    answer: "a",
  },
  {
    answer: "a",
  },

  {
    answer: "a",
  },
  {
    answer: "a",
  },

  {
    answer: "a",
  },
  {
    answer: "a",
  },

  {
    answer: "a",
  },
  {
    answer: "a",
  },

  {
    answer: "a",
  },
  {
    answer: "a",
  },
];
