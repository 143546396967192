export const soalBahasainggris = [
    {
      soal: "I and my friends … in the library. We read some books",
      a: "Am",
      b: "Is",
      c: "Have",
      d: "Are",
  
      answer: "d",
    },
    {
      soal: "She … not work because she has the flu",
      a: "Is",
      b: "Does",
      c: "Do",
      d: "Be",
  
      answer: "b",
    },
    {
      soal: "Alina … song every night.",
      a: "Sings",
      b: "Sing",
      c: "Is",
      d: "Does",
  
      answer: "a",
    },
    {
      soal: "My father … tea every morning. ",
      a: "Drink",
      b: "Drinks",
      c: "Drinking",
      d: "Is",
  
      answer: "b",
    },
    {
      soal: "They … a test every week.",
      a: "Does",
      b: "Has",
      c: "Not",
      d: "Have",
  
      answer: "d",
    },
    {
      soal: "She is a student. She … at school.",
      a: "Studying",
      b: "Study",
      c: "Studies",
      d: "Does",
  
      answer: "c",
    },
    {
      soal: "We … soccer match.",
      a: "Doing",
      b: "Watching",
      c: "Watches",
      d: "Watch",
      answer: "d",
    },
    {
      soal: "Gina cooks fried rice. It … amazing.",
      a: "Does",
      b: "Do",
      c: "Are",
      d: "Is",
  
      answer: "d",
    },
    {
      soal: "My brother rides a bike to school …",
      a: "Everyday",
      b: "Last day",
      c: "Next week",
      d: "Next time",
  
      answer: "a",
    },
    {
      soal: "Tomy, Jane, Mark, Sarah ………. smart students.",
      a: "Is",
      b: "Are",
      c: "Am",
      d: "Is not",
  
      answer: "b",
    },
    {
      soal: "Are you a doctor?  No, I ……….  a dentist.",
      a: "Am not",
      b: "Don't",
      c: "Am",
      d: "Was",
  
      answer: "c",
    },
    {
      soal: "………. your brother and sister ………. four languages?",
      a: "Dis-speak",
      b: "Does-speak",
      c: "Do-speaks",
      d: "Do-speak",
  
      answer: "d",
    },
    {
      soal: "Bob’s niece is very cute. ……….name is Mia.",
      a: "Her",
      b: "His",
      c: "He",
      d: "She",
      answer: "a",
    },
    {
      soal: "Most of us……….24 SKS this semester.",
      a: "to take",
      b: "are be taking",
      c: "are taking",
      d: "be taking",
      answer: "c",
    },
    {
      soal: "What are they doing right now?",
      a: "They are doing swimming",
      b: "They swim right now",
      c: "They are swimming",
      d: "The usually go swimming",
      answer: "c",
    },
    {
      soal: "What is he doing in the garden",
      a: "He is watching TV",
      b: "He is playing cards",
      c: " He is eating.",
      d: "He is planting flowers.",
      answer: "d",
    },
   
  ];
  
  
   